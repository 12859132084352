const API_URL = `https://mvfdi3wy33.execute-api.ap-south-1.amazonaws.com/dev`
const API_URL_NODE = `https://api.collegepass.org`
// const API_URL_NODE = `http://localhost:4000`
//  const API_URL = `http://localhost:5000`

export const APIgetSeriesList = `${API_URL_NODE}/api/v2/series/getSeriesList`

export const sendNotifcation = `${API_URL_NODE}/api/v2/pushnotification/sendmessage`

export const getEpisodewiseData = `${API_URL_NODE}/api/v2/admindashboard/getEpisodewiseData/`

export const getTotalUsers = `${API_URL_NODE}/api/v2/admindashboard/getTotalUsers/`

export const getActiveUsers = `${API_URL_NODE}/api/v2/admindashboard/getActiveUsers/`

export const getPlusUsers = `${API_URL_NODE}/api/v2/admindashboard/getPlusUsers/`

export const getPremiumPlusUsers = `${API_URL_NODE}/api/v2/admindashboard/getPremiumPlusUsers/`

export const getSpentTime = `${API_URL_NODE}/api/v2/admindashboard/getSpentTime/`

export const SpentTimePlus = `${API_URL_NODE}/api/v2/admindashboard/getTimeSpentPlus/`

export const getTimeSpentActiveUser = `${API_URL_NODE}/api/v2/admindashboard/getTimeSpentActiveUser/`

export const getVideosWatched = `${API_URL_NODE}/api/v2/admindashboard/getVideosWatched/`

export const getUniqueVideosWatched = `${API_URL_NODE}/api/v2/admindashboard/getUniqueVideosWatched/`

export const getVideoData = `${API_URL_NODE}/api/v2/admindashboard/getVideoData/`

export const insertSeries = `${API_URL_NODE}/api/v2/series/addSeries/`

export const getSeries = `${API_URL_NODE}/api/v2/series/getSeries/`

export const deleteSeries = `${API_URL_NODE}/api/v2/series/deleteSeries`

export const getSeriesWithEpisodes = `${API_URL_NODE}/api/v2/series/getSeriesWithEpisodes`

export const updateSeries = `${API_URL_NODE}/api/v2/series/updateSeries`

export const updateHomeSeries = `${API_URL_NODE}/api/v2/series/updateHomepageSeries`

export const authAPI = `${API_URL}/api/v1/auth/login`

export const getUsers = `${API_URL}/api/v1/getUsers`

export const getLimitedUsers = `${API_URL}/api/v1/getLimitedUsers`

export const getPrimePlusUser = `${API_URL}/api/v1/getPrimePlusUser`

export const updateUserDetail = `${API_URL}/api/v1/updateUserDetail/`

export const APIGetStates = 'https://api.collegepass.org/api/v1/profile/GetStateByCountryID/'

export const searchForUsers = `${API_URL}/api/v1/searchUser`

export const getEventFullData = `${API_URL}/api/v1/getEventFullData`

export const getEventRegistrationData = `${API_URL}/api/v1/getEventRegistrationData/`

export const getEventAttendeeData = `${API_URL}/api/v1/getEventAttendeeData/`

export const getArchiveVideos = `${API_URL}/api/v1/getAllArchiveVideos/`

export const getUserDetailByID = `${API_URL}/api/v1/getUserDetailByID/`

export const getClassesFullData = `${API_URL}/api/v1/getClassesFullData`

export const deleteEvent = `${API_URL}/api/v1/deleteEvent/`

export const insertNewClass = `${API_URL}/api/v1/insertNewClass`

export const insertArchiveClasses = `${API_URL}/api/v1/insertArchiveClasses/`

export const getClassListDropDown = `${API_URL}/api/v1/getClassListDropDown/`

export const getEventListDropDown = `${API_URL}/api/v1/getEventListDropDown/`

export const insertArchiveLiveStream = `${API_URL}/api/v1/insertArchiveLiveStream/`

export const getEventFullDataByID = `${API_URL}/api/v1/getEventFullDataByID/`

export const APIGetEventTags = `${API_URL}/api/v1/getEventTags`

export const APIGetAllAMilers = `${API_URL}/api/v1/getAllMailers`

export const APISetMailer = `${API_URL}/api/v1/setMailer`

export const APISendMail = `${API_URL}/api/v1/sendMailNow/`

export const APIuserAdminDBDetails = `${API_URL}/api/v2/users/basic-user-details`

export const APIuserPrimeDetails = `${API_URL}/api/v2/users/prime-status`

export const APIuserPrimePlusDetails = `${API_URL}/api/v2/users/prime-plus-details`

export const APIupdatePrimeDetails = `${API_URL}/api/v2/users/prime-details/`

export const APIupdatePrimePlusDetails = `${API_URL}/api/v2/users/prime-plus-details/`

export const APIupdateMandatoryDetails = `${API_URL}/api/v2/users/basic-user-details/`

export const getContestRegistrationData = `${API_URL}/api/v2/users/sat-contest/`

export const APIupdateUserPassword = `${API_URL}/api/v2/users/update-user-password/`

// ***************************************************************************************************
export const APIGetEventUserDetails = `${API_URL_NODE}/api/v2/event/event-details`

export const APIGetContactLeadDetails = `${API_URL_NODE}/api/v2/event/contact-lead`

export const APIregister = `${API_URL_NODE}/api/v1/auth/register`

export const APIregistercheckV1 = `${API_URL_NODE}/api/v1/auth/checkV1` // added

export const insertArchiveLiveStreamV1 = `${API_URL_NODE}/api/v2/event/insertArchiveLiveStream/`

export const insertArchiveClassesV1 = `${API_URL_NODE}/api/v2/event/insertArchiveClasses/`

export const APIAddNewBlog = `${API_URL_NODE}/api/v2/blog/addNewBlog`

export const APIGetBlogs = `${API_URL_NODE}/api/v2/blog/getBlogs/`

export const APIGetBlog = `${API_URL_NODE}/api/v2/blog/getBlog/`

export const APIUpdateBlog = `${API_URL_NODE}/api/v2/blog/updateBlog/`

export const APIDeleteBlog = `${API_URL_NODE}/api/v2/blog/deleteBlog/`

export const APIGetBlogsData = `${API_URL_NODE}/api/v2/blog/getBlogsData`

export const updateScheduledJob = `${API_URL_NODE}/api/v2/pushnotification/updateScheduledJob`

export const cancelScheduledJob = `${API_URL_NODE}/api/v2/pushnotification/cancelScheduledJob`

export const getUserMobileNotifications = `${API_URL_NODE}/api/v2/pushnotification/getUserMobileNotifications`

export const getCampaigns = `${API_URL_NODE}/api/v2/pushnotification/getCampaigns`

export const getCampaign = `${API_URL_NODE}/api/v2/pushnotification/getCampaign`

export const insertEventDetails = `${API_URL_NODE}/api/v2/event/insertEventDetails/`

export const updateEventDetails = `${API_URL_NODE}/api/v2/event/updateEventDetails/`

export const getEventFullDataByIDV1 = `${API_URL_NODE}/api/v2/event/getEventFullDataByID/`

export const getTags = `${API_URL_NODE}/api/v2/event/getTags`

export const APIAddPost = `${API_URL_NODE}/api/v2/post/add-post`

export const APIUpdatePost = `${API_URL_NODE}/api/v2/post/update-post`

export const APIGetPosts = `${API_URL_NODE}/api/v2/post/getPosts`

export const APIGetPostCount = `${API_URL_NODE}/api/v2/post/getPostCount`

export const APIGetPost = `${API_URL_NODE}/api/v2/post/getPost`

export const APIUpdateArchiveVideo = `${API_URL_NODE}/api/v2/event/updateVimeoId`

export const APIDeleteArchiveVideo = `${API_URL_NODE}/api/v2/event/deleteArchiveVideo/`

export const insertArchiveLiveStreamV2 = `${API_URL_NODE}/api/v2/event/insertArchiveLiveStreamV2/`

export const insertArchiveClassesV2 = `${API_URL_NODE}/api/v2/event/insertArchiveClassesV2/`

export const APIAddStory = `${API_URL_NODE}/api/v2/story/add-story`

export const APIUpdateStory = `${API_URL_NODE}/api/v2/story/update-story`

export const APIGetStory = `${API_URL_NODE}/api/v2/story/get-story/`

export const APIGetStories = `${API_URL_NODE}/api/v2/story/get-stories`

export const APIDeleteStory = `${API_URL_NODE}/api/v2/story/delete-story/`

export const APICheckToken = `${API_URL_NODE}/api/v2/utils/check-token/`

export const APIGetBlogsByName = `${API_URL_NODE}/api/v2/blog/getBlogsByName/`

export const APIGetPostsByName = `${API_URL_NODE}/api/v2/post/getPostsByName/`

export const sendNotifcationTesting = `${API_URL_NODE}/api/v2/pushnotification/sendmessageTesting`

export const getEventAttendeeDataV1 = `${API_URL_NODE}/api/v2/event/getEventAttendeeData/`

export const getPhysicalEventFullData = `${API_URL_NODE}/api/v2/event/getPhysicalEventFullData`

export const getPhysicalEventFullDataById = `${API_URL_NODE}/api/v2/event/getPhysicalEventFullDataById/`

export const insertPhysicalEventDetails = `${API_URL_NODE}/api/v2/event/insertPhysicalEventDetails/`

export const updatePhysicalEventDetails = `${API_URL_NODE}/api/v2/event/updatePhysicalEventDetails/`

export const APIAddNewBlogV1 = `${API_URL_NODE}/api/v2/blog/addNewBlogV1`

export const APIGetBlogsDataAdmin = `${API_URL_NODE}/api/v2/blog/getBlogsDataAdmin`

export const APICancelScheduledBlog = `${API_URL_NODE}/api/v2/blog/cancelScheduledBlog/`

export const getEventListDropDownV1 = `${API_URL_NODE}/api/v2/event/getEventListDropDownV1/`

export const APIregisterV1 = `${API_URL_NODE}/api/v1/auth/registerV1`

export const APIUpdateCampaignTitleAndDescription = `${API_URL_NODE}/api/v2/pushnotification/updateCampaignTitleAndDescription`

export const getCampaignsByDate = `${API_URL_NODE}/api/v2/pushnotification/getCampaignsByDate`

export const getReferralData = `${API_URL_NODE}/api/v2/referral/getReferralData`

export const getEventRegistrationDataV1 = `${API_URL_NODE}/api/v2/event/getEventRegistrationDataV1/`

export const getReferralCount = `${API_URL_NODE}/api/v2/referral/referralCount`

export const getReferralBulkData = `${API_URL_NODE}/api/v2/referral/getReferralBulkData`

// CMS API Paths
export const APIPostReviewOnlineCourse = `${API_URL_NODE}api/cms/reviewOnlinecourse`

export const sendOnesignalNotificationTesting = `${API_URL_NODE}/api/v2/pushnotification/sendOnesignalNotificationsTesting`

export const sendOnesignalNotification = `${API_URL_NODE}/api/v2/pushnotification/sendOnesignalNotification`

export const APICaptureMandatoryDetails = `${API_URL_NODE}/api/v2/user/captureUserDetails`

export const APITimeZoneData = `${API_URL_NODE}/api/v2/getTimeZoneDetails` // add this : timezone@astitva

export const APIgetAllCitiesByName = `${API_URL_NODE}/api/v2/utils/citiesByName/`

export const APIgetCitiById = `${API_URL_NODE}/api/v2/utils/citiById/`

export const getCPUsers = `${API_URL_NODE}/advisingApi/v1/getCPUsers`

export const APIAddPremiumPlusUser = `${API_URL_NODE}/api/v2/admin/addPremiumPlusUser`

export const APIGetPendingPremiumPlusUser = `${API_URL_NODE}/api/v2/admin/getPendingPremiumPlusUser`

export const APIUpdatePremiumStatus = `${API_URL_NODE}/api/v2/admin/updatePremiumStatus`

export const APIAddCounsellors = `${API_URL_NODE}/api/v2/admin/addCounsellors`

export const APIupdateMandatoryDetailsV2 = `${API_URL_NODE}/api/v2/admin/basic-user-details`

export const APIupdateUserPasswordV2 = `${API_URL_NODE}/api/v2/admin/update-user-password`

export const APIupdatePrimeDetailsV2 = `${API_URL_NODE}/api/v2/admin/prime-details/`

export const APIupdatePrimePlusDetailsV2 = `${API_URL_NODE}/api/v2/admin/prime-plus-details/`

export const APISearchForUsers = `${API_URL_NODE}/api/v2/admin/searchUser`

export const APIupdateUserEmail = `${API_URL_NODE}/api/v2/admin/update-user-email`
