import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import PrivateRoute from './routes/PrivateRoute'
import SidebarLayout from './layouts/sidebarLayout/index'
import Homepage from './pages/Homepage'
import UserList from './pages/Pages/UserList'
import Login from './pages/Pages/Login'
import EditUser from './pages/Pages/EditUser'
import Events from './pages/Pages/Events'
import EditEvent from './pages/Pages/EditEvent'
import Classes from './pages/Pages/Classes'
import AddClass from './pages/Pages/AddClass'
import Notifications from './pages/Pages/SendNotification'
import Dashboard from './pages/Pages/Dashboard'
import ReviewTable from './pages/Pages/ReviewTable'
import Series from './pages/Pages/Series'
import EditSeries from './pages/Pages/EditSeries'
import SeriesList from './pages/Pages/SeriesList'
import IndividualStats from './pages/Pages/IndividualStats'
import EventDetails from './pages/Pages/EventDetails'
import UploadRecording from './pages/Pages/UploadRecording'
import AddStudent from './pages/Pages/AddStudent'
import ContactLead from './pages/Pages/ContactLead'
import EditBlog from './pages/Pages/EditBlog'
import BlogsList from './pages/Pages/BlogsList'
import NotificationList from './pages/Pages/NotificationList'
import PostEdit from './pages/Pages/PostEdit'
import PostList from './pages/Pages/PostList'
import EditSuccessStory from './pages/Pages/EditSuccessStory'
import StoriesList from './pages/Pages/StoriesList'
import PhysicalEventList from './pages/Pages/PhysicalEventList'
import EditPhysicalEvent from './pages/Pages/EditPhysicalEvent'
import NotificationAnalytics from './pages/Pages/NotificationAnalytics'
import ReferralList from './pages/Pages/ReferralList'
import ReferralAnaylitics from './pages/Pages/ReferralAnaylitics'
import SendOneSignalAndFirebaseNotification from './pages/Pages/SendOneSignalAndFirebaseNotification'
import AddPremiumPlusUser from './pages/Pages/AddPremiumPlusUser'


const RoutesList = [
  {
    title: 'Houston - CollegePass',
    description: 'Admin panel for CollegePass',
    keywords: 'Admin Panel',
    path: '/',
    component: <Homepage />,
    isPrivate: false,
  },

  {
    title: 'Login',
    path: '/login',
    component: <Login />,
    isPrivate: false,
  },

  {
    title: 'User List',
    path: '/user-list-100',
    component: <UserList />,
    isPrivate: false,
  },

  {
    title: 'Edit User',
    path: '/user/edit-user/:id',
    component: <EditUser />,
    isPrivate: false,
  },

  {
    title: 'Events',
    path: '/events',
    component: <Events />,
    isPrivate: false,
  },

  {
    title: 'Edit Event',
    path: '/events/edit-event/:id',
    component: <EditEvent />,
    isPrivate: false,
  },
  {
    title: 'Edit Event',
    path: '/events/add-event',
    component: <EditEvent />,
    isPrivate: false,
  },
  {
    title: 'Classes',
    path: '/classes',
    component: <Classes />,
    isPrivate: false,
  },
  {
    title: 'Classes',
    path: '/classes/add-class',
    component: <AddClass />,
    isPrivate: false,
  },
  {
    title: 'Notifications',
    path: '/notifications',
    component: <Notifications />,
    isPrivate: false,
  },
  {
    title: 'Dashboard',
    path: '/dashboard',
    component: <Dashboard />,
    isPrivate: false,
  },
  {
    title: 'ReviewTable',
    path: '/review-table',
    component: <ReviewTable />,
    isPrivate: false,
  },
  {
    title: 'Series',
    path: '/series/',
    component: <Series />,
    isPrivate: false,
  },

  {
    title: 'SeriesList',
    path: '/series-list/',
    component: <SeriesList />,
    isPrivate: false,
  },

  {
    title: 'EditSeries',
    path: '/series/edit-series/:id',
    component: <EditSeries />,
    isPrivate: false,
  },
  {
    title: 'IndividualStats',
    path: '/individual-stats/',
    component: <IndividualStats />,
    isPrivate: false,
  },
  {
    title: 'UploadRecords',
    path: '/archive-video',
    component: <UploadRecording />,
    isPrivate: false,
  },
  // {
  //   title: 'Series',
  //   path: '/series/',
  //   component: <Series />,
  //   isPrivate: false,
  // },
  {
    title: 'EventDetails',
    path: '/event-details/',
    component: <EventDetails />,
    isPrivate: false,
  },
  //
  {
    title: 'AddStudent',
    path: '/add-student',
    component: <AddStudent />,
    isPrivate: false,
  },
  {
    title: 'ContactLead',
    path: '/contact-lead',
    component: <ContactLead />,
    isPrivate: false,
  },
  {
    title: 'BlogsList',
    path: '/blog-list',
    component: <BlogsList />,
    isPrivate: false,
  },
  {
    title: 'Blogs',
    path: '/edit-blog',
    component: <EditBlog />,
    isPrivate: false,
  },
  {
    title: 'Blogs',
    path: '/edit-blog/:id',
    component: <EditBlog />,
    isPrivate: false,
  },
  {
    title: 'Campaign List',
    path: '/campaign-list',
    component: <NotificationList />,
    isPrivate: false,
  },
  {
    title: 'Notifications',
    path: '/notifications/:id',
    component: <Notifications />,
    isPrivate: false,
  },
  {
    title: 'AddPost',
    path: '/edit-post',
    component: <PostEdit />,
    isPrivate: false,
  },
  {
    title: 'EditPost',
    path: '/edit-post/:id',
    component: <PostEdit />,
    isPrivate: false,
  },
  {
    title: 'PostList',
    path: '/post-list',
    component: <PostList />,
    isPrivate: false,
  },
  {
    title: 'SuccessStories',
    path: '/stories-list',
    component: <StoriesList />,
    isPrivate: false,
  },
  {
    title: 'SuccessStories',
    path: '/edit-story',
    component: <EditSuccessStory />,
    isPrivate: false,
  },
  {
    title: 'SuccessStories',
    path: '/edit-story/:id',
    component: <EditSuccessStory />,
    isPrivate: false,
  },
  {
    title: 'Physical Events',
    path: '/physical-events-list',
    component: <PhysicalEventList />,
    isPrivate: false,
  },
  {
    title: 'Physical Events',
    path: '/physical-event/edit-event',
    component: <EditPhysicalEvent />,
    isPrivate: false,
  },
  {
    title: 'Physical Events',
    path: '/physical-event/edit-event/:id',
    component: <EditPhysicalEvent />,
    isPrivate: false,
  },
  {
    title: 'Notification Analytics',
    path: '/notification-analytics',
    component: <NotificationAnalytics />,
    isPrivate: false,
  },
  {
    title: 'Referral List',
    path: '/referral-list',
    component: <ReferralList />,
    isPrivate: false,
  },
  {
    title: 'Referral List',
    path: '/referral-analytics',
    component: <ReferralAnaylitics />,
    isPrivate: false,
  },
  {
    title: 'New Notifications',
    path: '/onesignal-firebase-notifications',
    component: <SendOneSignalAndFirebaseNotification />,
    isPrivate: false,
  },
  {
    title: 'New Notifications',
    path: '/onesignal-firebase-notifications/:id',
    component: <SendOneSignalAndFirebaseNotification />,
    isPrivate: false,
  },
  {
    title: 'Add Premium Plus User',
    path: '/add-premium-plus-user',
    component: <AddPremiumPlusUser />,
    isPrivate: false,
  },
]

const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <SidebarLayout>
          <Routes>
            {RoutesList.map((item) =>
              item.isPrivate ? (
                <PrivateRoute path={item.path} component={item.component} key={item.path} />
              ) : (
                <Route path={item.path} element={item.component} key={item.path} />
              )
            )}
          </Routes>
        </SidebarLayout>
      </BrowserRouter>
    </div>
  )
}

export default App
