import { useCallback, useState } from 'react'

const useForm = (initialValues = {}, initialErrors = {}) => {
  const [formDetails, setFormDetails] = useState(initialValues)
  const [formErrors, setFormErrors] = useState(initialErrors)
  const [showFormErrors, setShowFormErrors] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const onTextInputChange = useCallback((id, value, isValid) => {
    setFormDetails((state) => {
      return { ...state, [id]: value }
    })

    setFormErrors((state) => {
      return { ...state, [id]: !isValid }
    })
  }, [])

  const onSelectInputChange = useCallback((id, value, isValid) => {
    setFormDetails((state) => {
      return { ...state, [id]: value }
    })

    console.log('Validation result', isValid)
    setFormErrors((state) => {
      return { ...state, [id]: !isValid }
    })
  }, [])

  const onMultiSelectInputChange = useCallback((id, value, isValid) => {
    setFormDetails((state) => {
      return { ...state, [id]: value }
    })

    console.log('Getting array of values', value)

    setFormErrors((state) => {
      return { ...state, [id]: !isValid }
    })
  }, [])

  const onRadioInputChange = useCallback((id, value, isValid) => {
    setFormDetails((state) => {
      return { ...state, [id]: value }
    })

    setFormErrors((state) => {
      return { ...state, [id]: !isValid }
    })
  }, [])

  const onDateInputChange = useCallback((id, value, isValid) => {
    setFormDetails((state) => {
      return { ...state, [id]: value }
    })

    setFormErrors((state) => {
      return { ...state, [id]: !isValid }
    })
  }, [])

  return {
    values: formDetails,
    errors: formErrors,
    showFormErrors,
    isLoading,
    setFormDetails,
    setShowFormErrors,
    onTextInputChange,
    onSelectInputChange,
    onRadioInputChange,
    onMultiSelectInputChange,
    onDateInputChange,
    setIsLoading,
  }
}

export default useForm
